import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmail, toggleModal } from "../state/store";
import { gtm } from "../tracking/gtm";

export default function RequestAccessForm() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.signUp.open);
  const email = useSelector((state) => state.signUp.email);
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        onClose={() => dispatch(toggleModal())}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-30" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg p-12 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h2"
                className="text-2xl font-medium leading-6 text-gray-800 pb-2 text-center"
              >
                Join waitlist
              </Dialog.Title>
              <Dialog.Description className="text-gray-500 text-md pb-2 text-center">
                We won't spam you, we'll be in touch to onboard you when there's
                a space available.
              </Dialog.Description>

              <div id="mc_embed_signup">
                <form
                  action="https://supersheets.us5.list-manage.com/subscribe/post?u=ef4f58698298eaffc50ff19af&amp;id=cfd3a6f2b4"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  noValidate
                >
                  <div id="mc_embed_signup_scroll" className="validate">
                    <TextInput
                      id="mce-EMAIL"
                      name="EMAIL"
                      text="Email"
                      value={email}
                      fn={setEmail}
                    />
                    <div id="mce-responses" className="clear">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    <div
                      style={{ position: "absolute", left: "-5000px" }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_ef4f58698298eaffc50ff19af_cfd3a6f2b4"
                        tabIndex="-1"
                        value=""
                        onChange={() => {}}
                      />
                    </div>
                    <div className="flex justify-start pt-2">
                      <button
                        type="submit"
                        value="Submit"
                        name="submit"
                        id="mc-embedded-subscribe"
                        className="button inline-flex w-full justify-center py-3 px-4 border border-transparent shadow-sm text-md rounded-md text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => {
                          gtm({
                            event: "submit_request_access",
                            action: "click",
                            properties: {
                              location: "request-access-form",
                            },
                          });
                          dispatch(toggleModal());
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

function TextInput(props) {
  const dispatch = useDispatch();
  return (
    <div className="mc-field-group mt-4 mb-6">
      <div className="mt-1">
        <input
          type="text"
          id={props.id}
          value={props.value}
          name={props.name}
          placeholder={props.text}
          className={`required ${
            props.name === "EMAIL" ? "email" : ""
          } block max-w-lg w-full p-3 text-sm shadow-sm border border-gray-200 rounded-md`}
          onChange={(e) => {
            dispatch(props.fn(e.target.value));
          }}
        />
      </div>
    </div>
  );
}
