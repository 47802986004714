import React from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { useDispatch } from "react-redux";
import { toggleModal } from "../state/store";
import { classNames } from "../utils";

import Logo from "./logo";
import RequestAccess from "./request-access";
import SignIn from "./SignIn";
import { Link } from "gatsby";

export default function Header(props) {
  const whiteNavItems = props.atTop && props.homePage;
  return (
    <div
      className={classNames(
        "fixed w-full transition duration-200 z-20 h-16 flex",
        props.atTop
          ? ""
          : "shadow-sm backdrop-filter backdrop-blur-md bg-white bg-opacity-70"
      )}
    >
      <nav
        className="justify-between w-full max-w-screen-2xl relative m-auto px-4 md:px-8 lg:px-16 grid grid-cols-3 items-center"
        aria-label="Global"
      >
        <div className="col-span-1">
          <Logo className="justify-start" white={whiteNavItems} />
        </div>
        <div className="hidden md:flex md:col-span-1">
          <WebNav whiteNav={whiteNavItems} />
        </div>
        <div className="hidden md:col-span-1 md:grid md:grid-cols-1">
          <WebActions whiteNav={whiteNavItems} />
        </div>
        <div className="col-span-2 place-self-end md:hidden">
          <MobileActions whiteNav={whiteNavItems} />
        </div>
      </nav>
    </div>
  );
}

function WebNav(props) {
  return (
    <div className="flex-grow justify-center space-x-8 flex">
      {/* <NavItem whiteNav={props.whiteNav} text="Pricing" to="/pricing" /> */}
      <NavItem whiteNav={props.whiteNav} text="About" to="/about" />
      {/* <NavItem whiteNav={props.whiteNav} text="Blog" to="/blog" /> */}
    </div>
  );
}

function WebActions(props) {
  return (
    <div className="flex items-center justify-end">
      <SignIn white={props.whiteNav} />
      <RequestAccess white={props.whiteNav} />
    </div>
  );
}

function MobileActions(props) {
  const dispatch = useDispatch();
  return (
    <Popover>
      <div className="flex items-center flex-1 md:hidden md:absolute md:inset-y-0 md:left-0">
        <div className="flex items-center justify-between w-full md:w-auto">
          <OpenMobileMenuButton whiteNav={props.whiteNav} />
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute md:hidden z-40 right-4 top-4 w-11/12 m-auto px-12 py-8 bg-white rounded-md shadow-2xl border-gray-300"
        >
          <Popover.Button className="absolute right-4 top-2 bg-white rounded-md p-2 inline-flex items-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
            <span className="sr-only">Close main menu</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
          <div className="flex flex-col justify-center">
            <MobileMenuLink to="/" name="Home" />
            <MobileMenuLink to="/about" name="About" />
            {/* <MobileMenuLink to="/blog" name="Blog" /> */}
            {/* <MobileMenuLink to="/pricing" name="Pricing" /> */}
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <button
                className="block w-full py-3 px-4 rounded-md shadow bg-primary-400  text-white  hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-200 focus:ring-offset-primary-400"
                onClick={() => {
                  dispatch(toggleModal());
                }}
              >
                <span className="text-sm font-medium">Join Waitlist</span>
              </button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

function NavItem(props) {
  return (
    <Link
      to={props.to}
      className={`${
        props.whiteNav
          ? "text-white hover:text-primary-100"
          : "text-gray-600 hover:text-gray-700"
      } text-sm font-medium py-4`}
    >
      {props.text}
    </Link>
  );
}

function OpenMobileMenuButton(props) {
  let buttonCss = [
    "rounded-md",
    "p-2",
    "inline-flex",
    "items-center",
    "justify-center",
  ];
  if (props.whiteNav) {
    buttonCss.push(...["text-white", "hover:text-primary-100"]);
  } else {
    buttonCss.push(...["text-primary-400", "hover:text-primary-500"]);
  }
  return (
    <div className="-mr-2 flex items-center md:hidden">
      <Popover.Button ref={props.popperRef} className={buttonCss.join(" ")}>
        <span className="sr-only">Open main menu</span>
        <MenuIcon className="h-7 w-7" aria-hidden="true" />
      </Popover.Button>
    </div>
  );
}

function MobileMenuLink(props) {
  return (
    <Link
      to={props.to}
      className="text-primary-400 hover:text-primary-500 hover:bg-gray-50 rounded-md text-sm font-medium py-4 px-8 text-center z-10"
    >
      {props.name}
    </Link>
  );
}
