import React from "react";
import { useDispatch } from "react-redux";
import { gtm } from "../tracking/gtm";
import { toggleModal } from "../state/store";

export default function RequestAccess(props) {
  const dispatch = useDispatch();
  const css = [
    "inline-flex",
    "items-center",
    "px-4",
    "py-2",
    "border",
    "text-sm",
    "font-medium",
    "rounded-md",
  ];
  if (props.white) {
    css.push(
      ...[
        "border-2",
        "border-white",
        "text-white",
        "bg-none",
        "hover:text-primary-100",
        "hover:border-primary-100",
      ]
    );
  } else {
    css.push(
      ...[
        "border-transparent",
        "hover:bg-primary-500",
        "text-white",
        "bg-primary-400",
        "hover:bg-primary-500",
      ]
    );
  }
  return (
    <span className="inline-flex rounded-md shadow">
      <button
        className={css.join(" ")}
        onClick={() => {
          gtm({
            event: "open_request_access",
            action: "click",
            properties: {
              location: "request_access_form",
            },
          });
          dispatch(toggleModal());
        }}
      >
        Join waitlist
      </button>
    </span>
  );
}
