import { useStaticQuery, graphql } from "gatsby";
import { kebabCase } from "lodash";

export default function useProfilePicture(name) {
  const images = {};
  const { allFile } = useStaticQuery(
    graphql`
      query AllProfilePictures {
        allFile(filter: { relativeDirectory: { eq: "people" } }) {
          edges {
            node {
              childrenImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 400, width: 400)
              }
              name
            }
          }
        }
      }
    `
  );
  allFile.edges.forEach((image) => {
    images[image.node.name] = image.node.childrenImageSharp[0].gatsbyImageData;
  });
  return images[kebabCase(name)];
}
