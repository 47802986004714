import React from "react";
import { Link } from "gatsby";
import Logo from "../../assets/images/logo.svg";
import LogoWhite from "../../assets/images/logo-white.svg";

export default function Log(props) {
  return (
    <Link to="/">
      <div className="w-36">{props.white ? <LogoWhite /> : <Logo />}</div>
    </Link>
  );
}
