import { Link } from "gatsby";
import * as React from "react";
import { GitHub, LinkedIn, Twitter } from "./icons";

export default function Footer() {
  return (
    <footer className="bg-gy-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-3">
          {navigation.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-2 text-center flex space-x-8">
          <Link to="/privacy" className="text-gray-400 hover:text-gray-500">
            Privacy Policy
          </Link>
          <Link to="/terms" className="text-gray-400 hover:text-gray-500">
            Terms
          </Link>
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-400">
            &copy; 2022 Supersheets Ltd
          </p>
        </div>
      </div>
    </footer>
  );
}

const navigation = {
  social: [
    {
      name: "GitHub",
      href: "https://github.com/supersheetsso",
      icon: (props) => <GitHub {...props} />,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/getsupersheets",
      icon: (props) => <Twitter {...props} />,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/supersheets",
      icon: (props) => <LinkedIn {...props} />,
    },
  ],
};
