import React from "react";

export default function SignIn(props) {
  const css = [
    "inline-flex",
    "items-center",
    "px-6",
    "py-2",
    "border",
    "text-sm",
    "font-medium",
    "rounded-md",
    "border-none",
  ];
  if (props.white) {
    css.push(...["text-white", "hover:text-primary-100"]);
  } else {
    css.push(...["text-primary-400", "hover:text-primary-500"]);
  }
  return (
    <span className="inline-flex rounded-md items-center">
      <a href="https://app.supersheets.so" className={css.join(" ")}>
        Sign in
      </a>
    </span>
  );
}
